/*
    A copy of AsyncEvent is also available in Nuxt repository
    Because both are dependent you must update changes to the repository
    too. This AsyncEvent is normally running after the Nuxt version.

    The Reason behind two async event classes is, that nuxt is not available in hc javascript.
    HC javascript however calls "select customer" (example) before nuxt is loaded, losing the event in nuxt if not saved somewhere.
 */

class AsyncEventHc {
  constructor() {
    this.events = {}
    this.listeners = []
  }

  isRegistered(eventName) {
    return Object.prototype.hasOwnProperty.call(this.events, eventName)
  }

  dispatch(eventName, payload = null) {
    this.events[eventName] = payload
    this.on(eventName)
  }

  /**
   * Used in nuxt. Because AsyncEvent is shared, this must be implemented here too
   */
  // noinspection JSUnusedGlobalSymbols
  onMany(array, vueInstance) {
    array.forEach((item) => {
      this.on(item.eventName, item.callback, vueInstance || item.vueInstance)
    })
  }

  on(eventName, callback, vueInstance) {
    if (callback) {
      this.listeners.push({
        eventName: eventName,
        callback: callback,
      })

      if (vueInstance && typeof vueInstance === 'object' && vueInstance.$once) {
        vueInstance.$once('hook:beforeDestroy', () => {
          this.off(eventName, callback)
        })
      }
    }

    if (!this.isRegistered(eventName)) {
      return
    }

    this.listeners.forEach((listener) => {
      if (listener.eventName === eventName) {
        listener.callback(this.events[eventName])
      }
    })
  }

  off(eventName, callback) {
    const index = this.listeners
      .map((l) => (l.eventName === eventName ? l.callback : null))
      .filter(Boolean)
      .indexOf(callback)

    if (index > -1) {
      this.listeners.splice(index, 1)
    }
  }
}

/**
 * Force nuxt and hc javascript to use just one event bus
 */
if (!window.asyncEvent) {
  window.asyncEvent = new AsyncEventHc()
}

export const asyncEvent = window.asyncEvent
